import { formatDateToYYYYMMDDHHmmForInput } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputProps,
  Portal,
  Spacer,
  StyleProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { MdCalendarToday, MdClose } from 'react-icons/md';
import DateItemPicker from './DateItemPicker';
import { InputTag, InputTagProps } from './InputTag';

type DateTimePickerProps = {
  label?: string;
  locale?: string;
  placeholder?: string;
  value: string | null;
  ariaLabel?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  sx?: StyleProps;
  inputProps?: InputProps;
  onChange: (date: string | null) => void;
  inputTagProps?: InputTagProps;
};

// Note: For avoiding many element nodes in DOM.
const YEARS = Array.from(Array(80).keys()).map((year) => year + 2020); // 2020 - 2100
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const JAPANESE_MONTHS = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
];

const HOURS = Array.from(Array(24).keys());
const MINUTES = Array.from(Array(60).keys());

const formatDate = (item: string | number) => ('' + item).padStart(2, '0');
const formatHourOrMinutes = (item: string | number) => ('' + item).padStart(2, '0');

const DateTimePicker = (props: DateTimePickerProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMobile } = useScreenInfos();

  const {
    value,
    onChange,
    placeholder,
    label = t('form.select.datetime', 'Date and time'),
    ariaLabel,
    locale,
    errorMessage,
    isInvalid,
    sx,
    inputProps,
    inputTagProps,
  } = props;

  const dateValue = useMemo(() => {
    return value ? new Date(value) : null;
  }, [value]);

  const getCurrentValue = (format: string, defaultValue: number) => {
    return dateValue ? parseInt(dayjs(dateValue).format(format)) : defaultValue;
  };

  const currentMinute = getCurrentValue('m', dayjs().minute());
  const currentHour = getCurrentValue('H', dayjs().hour());
  const currentDay = getCurrentValue('D', dayjs().date());
  const currentMonthIndex = dateValue
    ? parseInt(dayjs(dateValue).format('M')) - 1
    : dayjs().month();
  const currentYear = getCurrentValue('YYYY', dayjs().year());
  const currentYearIndex = YEARS.findIndex((year) => year === currentYear);

  const [minute, setMinute] = useState<number>(currentMinute);
  const [hour, setHour] = useState<number>(currentHour);
  const [day, setDay] = useState<number>(currentDay);
  const [year, setYear] = useState<number>(currentYear);

  const [monthIndex, setMonthIndex] = useState<number>(currentMonthIndex);
  const [yearIndex, setYearIndex] = useState<number>(currentYearIndex);

  const days = useMemo(() => {
    const days = [];
    const daysInMonth = dayjs(`${MONTHS[monthIndex]} 1, ${year}`).daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days;
  }, [monthIndex, year]);

  const resetValues = () => {
    setMinute(currentMinute);
    setHour(currentHour);
    setMonthIndex(currentMonthIndex);
    setYearIndex(currentYearIndex);
    setDay(currentDay);
    setYear(currentYear);
  };

  const onMinuteChange = useCallback((index: number) => {
    setMinute(index);
  }, []);

  const onHourChange = useCallback((index: number) => {
    setHour(index);
  }, []);

  const onMonthChange = useCallback((index: number) => {
    setMonthIndex(index);
  }, []);

  const onDayChange = useCallback((index: number) => {
    setDay(index + 1);
  }, []);

  const onYearChange = useCallback((index: number) => {
    setYear(YEARS[index]);
    setYearIndex(index);
  }, []);

  const onClear = () => {
    onChange(null);
    resetValues();
  };

  const handleChange = () => {
    const dateString = `${MONTHS[monthIndex]}-${day}-${year} ${hour}:${minute}`;
    const date = formatDateToYYYYMMDDHHmmForInput(new Date(dateString));
    onChange(date);
    handleClose();
  };

  const handleClose = () => {
    resetValues();
    onClose();
  };

  return (
    <>
      {isMobile ? (
        <>
          <VStack alignItems='start' w='full' sx={sx}>
            <FormControl isInvalid={isInvalid}>
              <FormLabel as='label'>
                <Flex gap={2} alignItems='center'>
                  {label}
                  {inputTagProps && <InputTag {...inputTagProps} />}
                </Flex>
              </FormLabel>
              <Flex
                px={3}
                h='40px'
                cursor='pointer'
                border='1px solid'
                borderColor='neutral.300'
                borderRadius='md'
                w='full'
                alignItems='center'
              >
                <HStack onClick={onOpen} w='full' h='full'>
                  <Input
                    value={value ?? ''}
                    onChange={(e) => onChange(e.target.value ?? null)}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
                    type='datetime-local'
                    variant='unstyled'
                    readOnly
                    pointerEvents='none'
                  />
                </HStack>
                <Box ml='auto'>
                  {value ? (
                    <IconButton
                      size='xs'
                      onClick={onClear}
                      icon={<MdClose />}
                      aria-label='clear-date'
                      rounded='full'
                    />
                  ) : (
                    <IconButton
                      size='xs'
                      onClick={onOpen}
                      icon={<MdCalendarToday />}
                      aria-label='open-date-picker'
                      bg='transparent'
                    />
                  )}
                </Box>
              </Flex>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          </VStack>
          <Portal>
            <Drawer placement='bottom' onClose={handleClose} isOpen={isOpen} size='xl'>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>{label}</DrawerHeader>
                <DrawerBody p={0} m={0} justifyItems='center' alignItems='center'>
                  <Flex w='full' flexDir={{ base: 'row', md: 'column' }}>
                    <Box w='full' my={5}>
                      <Flex height='145px' alignItems='center' justifyContent='center'>
                        <Spacer />
                        {locale === 'ja' ? (
                          <>
                            <DateItemPicker
                              initialItem={yearIndex}
                              items={YEARS}
                              onItemChange={onYearChange}
                            />
                            <DateItemPicker
                              initialItem={monthIndex}
                              items={JAPANESE_MONTHS}
                              onItemChange={onMonthChange}
                            />
                            <DateItemPicker
                              initialItem={day - 1}
                              items={days}
                              onItemChange={onDayChange}
                              itemFormatter={formatDate}
                            />
                          </>
                        ) : (
                          <>
                            <DateItemPicker
                              initialItem={monthIndex}
                              items={MONTHS}
                              onItemChange={onMonthChange}
                            />
                            <DateItemPicker
                              initialItem={day - 1}
                              items={days}
                              onItemChange={onDayChange}
                              itemFormatter={formatDate}
                            />
                            <DateItemPicker
                              initialItem={yearIndex}
                              items={YEARS}
                              onItemChange={onYearChange}
                            />
                          </>
                        )}
                        {/* <OrderedDateItemsPicker /> */}
                        <Spacer />
                        <DateItemPicker
                          initialItem={hour}
                          items={HOURS}
                          onItemChange={onHourChange}
                          itemFormatter={formatHourOrMinutes}
                        />
                        <Box>:</Box>
                        <DateItemPicker
                          initialItem={minute}
                          items={MINUTES}
                          onItemChange={onMinuteChange}
                          itemFormatter={formatHourOrMinutes}
                        />
                        <Spacer />
                      </Flex>
                    </Box>
                  </Flex>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant='outline' mr={3} onClick={handleClose}>
                    {t('actions.cancel', 'Cancel')}
                  </Button>
                  <Button colorScheme='primary' onClick={handleChange}>
                    {t('actions.save', 'Save')}
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Portal>
        </>
      ) : (
        <FormControl isInvalid={isInvalid} mb={2}>
          <FormLabel as='label'>
            <Flex gap={2} alignItems='center'>
              {label}
              {inputTagProps && <InputTag {...inputTagProps} />}
            </Flex>
          </FormLabel>
          <Input
            type='datetime-local'
            aria-label={ariaLabel}
            {...(inputProps || {
              value: value ?? '',
              onChange: (e) => onChange(e.target.value ?? null),
            })}
          />
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
      )}
    </>
  );
};

export default DateTimePicker;
